import { jsx as _jsx } from "jsx-dom/jsx-runtime";
/** @jsxImportSource jsx-dom */
import { select } from "@fruugo/utilities";
import { addItemToCartRequest } from "../api";
import { openModal } from "../components/modal/Modal";
import { LoadingModalContent } from "../components/modal/LoadingModalContent";
import { ProductAddedToCartModalContent } from "../components/modal/ProductAddedToCartModalContent";
import { sendAddToCartEvent } from "../analytics";
import { logGoogleRecommendationsAIEvent } from "../analytics/google-analytics";
import { sendFacebookPixelEvent } from "../analytics/facebook-pixel";
import { FruugoAnalyticsAddToCartEvent } from "../analytics/fruugo-analytics/FruugoAnalyticsAddtoCartEvent";
import { i18n } from "../i18n";
import { ModalType } from "../components/modal/types";
export function handleAddToCart(skuId, quantity) {
    var _a = openModal(ModalType.ProductAddedToCart, _jsx(LoadingModalContent, {}), {
        ariaLabel: i18n("common.loading"),
    }), _ = _a[0], updateModal = _a[1];
    addItemToCartRequest(skuId, quantity)
        .then(function (response) {
        var _a, _b, _c;
        var cartValid = response.cartValid, success = response.success, checkoutUrl = response.checkoutUrl, message = response.message;
        updateModal(_jsx(ProductAddedToCartModalContent, { cartValid: cartValid, success: success, checkoutUrl: checkoutUrl, message: message, quantity: quantity }), {
            ariaLabel: i18n("product.modal.addedtocart"),
            ariaDescribedby: "added-to-cart-aria-description",
        });
        if (response.success === true) {
            window.totalCartItems = response.totalItemsInCart;
            var cartItems = select(".js-cart-counter");
            if (cartItems) {
                cartItems.classList.remove("d-none");
                cartItems.innerText = response.totalItemsInCart;
            }
        }
        sendAddToCartEvent(window.skuInfo, quantity);
        logGoogleRecommendationsAIEvent({
            eventType: "add-to-cart",
            attributes: {
                userCountry: { text: [window.currentCountry] },
                userLanguage: { text: [window.currentLang] },
                retailerId: { text: [(_a = window.retailerInformation) === null || _a === void 0 ? void 0 : _a.id] },
                retailerName: { text: [(_b = window.retailerInformation) === null || _b === void 0 ? void 0 : _b.name] },
                retailerCountry: { text: [(_c = window.retailerInformation) === null || _c === void 0 ? void 0 : _c.country] },
            },
            productDetails: [
                {
                    product: { id: window.productId, name: window.skuInfo.title },
                    quantity: quantity,
                },
            ],
            cartId: response.cartId.toString(),
        });
        sendFacebookPixelEvent("AddToCart", {
            value: window.skuInfo.price.numericTotalInclTax,
            currency: window.skuInfo.price.currencyCode,
            contents: [{ id: window.skuInfo.productId, quantity: quantity }],
            content_type: "product",
        });
        var event = new FruugoAnalyticsAddToCartEvent(window.productId);
        event.send();
    })
        .catch(function () {
        updateModal(_jsx(ProductAddedToCartModalContent, { cartValid: false, success: false, message: i18n("cart.generic.error") }), {
            ariaLabel: i18n("product.modal.addedtocart"),
            ariaDescribedby: "added-to-cart-aria-description",
        });
    });
}
