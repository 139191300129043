var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FruugoAnalyticsBaseEvent } from "./FruugoAnalyticsBaseEvent";
var pageType = window.pageType;
var FruugoAnalyticsInteraction = /** @class */ (function (_super) {
    __extends(FruugoAnalyticsInteraction, _super);
    function FruugoAnalyticsInteraction(apiUrl) {
        var _this = _super.call(this, apiUrl) || this;
        _this.getCarouselName = function (listName) {
            if (!listName)
                return pageType !== null && pageType !== void 0 ? pageType : "";
            if (listName.includes("search"))
                return pageType !== null && pageType !== void 0 ? pageType : "";
            var parts = listName.split(".");
            if (parts.length <= 1)
                return pageType !== null && pageType !== void 0 ? pageType : "";
            return "".concat(pageType, "_carousel_").concat(parts[1]);
        };
        _this.getCarouselPosition = function (listName) {
            if (!listName)
                return null;
            var parts = listName.split(".");
            if (parts.length < 2)
                return null;
            return parts[1];
        };
        return _this;
    }
    return FruugoAnalyticsInteraction;
}(FruugoAnalyticsBaseEvent));
export { FruugoAnalyticsInteraction };
