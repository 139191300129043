import { jsx as _jsx, jsxs as _jsxs } from "jsx-dom/jsx-runtime";
/** @jsxImportSource jsx-dom */
import Glide, { Breakpoints, Controls, Swipe, Images, Anchors, } from "@glidejs/glide/dist/glide.modular.esm";
import xhr from "./xhr.js";
import { createProductImpressionObserver } from "./analytics.js";
import { i18n } from "./i18n.js";
import { Logger, select, shuffleArray } from "@fruugo/utilities";
import { OnSiteAttribution, } from "../types";
var Log = new Logger(window.environment);
var observer = createProductImpressionObserver();
export var initCarousels = function (targets) {
    if (targets === void 0) { targets = document.getElementsByClassName("js-carousel"); }
    if (targets) {
        Array.from(targets).forEach(function (target) {
            var element = target;
            if (typeof element.dataset !== "undefined") {
                initCarousel(element)
                    .then(function (carousel) {
                    return getCarouselData(element).then(function (data) {
                        return populateCarousel(element, carousel, data);
                    });
                })
                    .catch(function (error) {
                    element.remove();
                    Log.error(error);
                });
            }
        });
    }
};
export var initCarouselGroup = function (targets) {
    if (targets === void 0) { targets = document.getElementsByClassName("js-carousel-group"); }
    if (targets) {
        Array.from(targets).forEach(function (target) {
            var element = target;
            if (typeof element.dataset !== "undefined") {
                var groupedItems_1 = element.dataset.groupedItems;
                getCarouselData(element)
                    .then(function (carousels) { return applyCarouselGroup(carousels, groupedItems_1); })
                    .catch(function (error) {
                    element.remove();
                    // Try to remove all carousel skeletons if endpoint errors
                    try {
                        var itemNames = groupedItems_1 === null || groupedItems_1 === void 0 ? void 0 : groupedItems_1.split(",");
                        itemNames === null || itemNames === void 0 ? void 0 : itemNames.forEach(function (name) {
                            var container = select("#".concat(name));
                            container === null || container === void 0 ? void 0 : container.remove();
                        });
                    }
                    catch (err) { }
                    Log.error(error);
                });
            }
        });
    }
};
function applyCarouselGroup(carousels, groupedItems) {
    if (!groupedItems)
        return;
    var itemNames = groupedItems.split(",");
    itemNames.forEach(function (name) {
        try {
            var data_1 = carousels[name];
            var container_1 = select("#".concat(name));
            if (container_1) {
                if (data_1) {
                    initCarousel(container_1).then(function (glideCarousel) {
                        return populateCarousel(container_1, glideCarousel, data_1);
                    });
                }
                else {
                    container_1.remove();
                    console.warn("Unable to map grouped carousel data with name: ".concat(name));
                }
            }
            else {
                console.warn("Unable to find grouped carousel item element with name: #".concat(name));
            }
        }
        catch (err) {
            Log.error(err);
        }
    });
}
var initCarousel = function (target) {
    return new Promise(function (resolve) {
        var carousel = new Glide(target, {
            type: "carousel",
            perView: 8,
            gap: 16,
            perSwipe: "|",
            direction: window.rtl ? "rtl" : "ltr",
            animationDuration: 800,
            peek: 16,
            breakpoints: {
                576: {
                    perView: 2,
                    peek: {
                        before: 16,
                        after: 50,
                    },
                    animationDuration: 200,
                },
                768: {
                    perView: 3,
                    peek: {
                        before: 16,
                        after: 50,
                    },
                    animationDuration: 300,
                },
                1280: {
                    perView: 5,
                    animationDuration: 800,
                },
                1600: {
                    perView: 6,
                },
            },
        });
        resolve(carousel);
    });
};
export var showCarouselArrows = function (target) {
    return target
        .querySelectorAll(".glide__arrow")
        .forEach(function (arrow) { return arrow.classList.add("d-md-block"); });
};
var createProductTileMarkup = function (data) {
    var _a;
    var hasVoucher = window.voucher;
    var tile = (_jsx("li", { class: "glide__slide", children: _jsxs("a", { href: (_a = data.productPageLink) === null || _a === void 0 ? void 0 : _a.href, class: "carousel-item", children: [_jsx("span", { class: "carousel-item-image", children: _jsx("img", { src: data.imageUrl
                            ? data.imageUrl
                            : "/marketplace/images/image-placeholder.png", alt: data.title, width: "120", height: "120", loading: "lazy", onError: function (event) { return handleImageError(event.target); } }) }), _jsx("h5", { class: "carousel-item-title", children: data.title }), hasVoucher && (_jsxs("div", { class: "carousel-item-discount", children: [_jsxs("p", { class: "mb-0", children: [i18n("product.recommendedPrice"), " ", _jsx("del", { children: data.normalPrice })] }), _jsx("p", { class: "mb-0", children: i18n("product.voucher.discount", [data.priceDiscount]) }), _jsx("p", { class: "carousel-item-discount-price mb-0", children: data.price })] })), !hasVoucher && (_jsxs("p", { class: "carousel-item-price", children: [data.price, " ", data.normalPrice && (_jsx("del", { class: "carousel-item-price-original", children: data.normalPrice }))] }))] }) }));
    var properties = [
        "category",
        "brand",
        "title",
        "productId",
        "price",
        "retailerId",
        "retailerName",
        "source",
    ];
    for (var _i = 0, properties_1 = properties; _i < properties_1.length; _i++) {
        var prop = properties_1[_i];
        if (prop === "source") {
            if (data["onSiteAttribution"] === OnSiteAttribution.BYTEPLUS) {
                tile.dataset[prop] = data["onSiteAttribution"];
            }
        }
        else {
            tile.dataset[prop] = data[prop];
        }
    }
    return tile;
};
var populateCarousel = function (target, carousel, data) {
    var productTiles = data.productTiles, title = data.title;
    var carouselWrapper = target.querySelector(".glide__slides");
    var minTiles = 4;
    var shuffle = target.dataset.shuffle;
    if (shuffle !== "false")
        shuffleArray(productTiles);
    if (carouselWrapper) {
        populateCarouselTitle(target, title);
        populateTiles(productTiles, target, carouselWrapper, createProductTileMarkup);
    }
    if (productTiles && productTiles.length < minTiles) {
        carousel.settings.type = "slider";
    }
    else {
        showCarouselArrows(target);
    }
    carousel.mount({ Breakpoints: Breakpoints, Controls: Controls, Swipe: Swipe, Images: Images, Anchors: Anchors });
};
var populateCarouselTitle = function (target, title) {
    var placeholder = target.querySelector(".loading");
    if (placeholder)
        placeholder.remove();
    if (!title)
        return;
    var carouselTitle = document.createElement("h4");
    carouselTitle.innerHTML = title;
    carouselTitle.classList.add("carousel-heading");
    target.insertBefore(carouselTitle, target.firstChild);
};
var populateTiles = function (tiles, target, carouselWrapper, createMarkup) {
    if (tiles && tiles.length > 0) {
        carouselWrapper.innerHTML = "";
        tiles.forEach(function (tile) {
            var markup = createMarkup(tile);
            carouselWrapper.appendChild(markup);
            observer.observe(markup);
        });
    }
    else {
        target.remove();
    }
};
function getCarouselData(target) {
    return new Promise(function (resolve, reject) {
        var endpoint = target.dataset.endpoint;
        xhr(endpoint)
            .then(function (data) { return JSON.parse(data.response); })
            .then(resolve)
            .catch(reject);
    });
}
