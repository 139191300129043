import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "jsx-dom/jsx-runtime";
/** @jsxImportSource jsx-dom */
import { i18n } from "../i18n";
import { openSideMenu, updateSideMenuBody } from "./SideMenu";
import { CategoryMenu } from "./CategoryMenu";
import { select } from "@fruugo/utilities";
import { Icon } from "./Icon";
var _a = window.pageUrls, homeUrl = _a.homeUrl, accountUrl = _a.accountUrl, helpCentreUrl = _a.helpCentreUrl, cartUrl = _a.cartUrl, whereIsMyOrderUrl = _a.whereIsMyOrderUrl;
export function initMainMenu() {
    var _a;
    var config = {
        openMainMenuClass: ".js-open-main-menu",
    };
    function openMainMenu() {
        openSideMenu(_jsx(MainMenu, {}));
    }
    (_a = select(config.openMainMenuClass)) === null || _a === void 0 ? void 0 : _a.addEventListener("click", openMainMenu);
}
export var MainMenu = function () {
    var handleDepartmentSelection = function () { return updateSideMenuBody(_jsx(CategoryMenu, {})); };
    return (_jsxs(_Fragment, { children: [_jsx("section", { children: _jsxs("ul", { children: [_jsx("li", { children: _jsxs("a", { href: homeUrl, autoFocus: true, class: "side-menu-item", children: [_jsx(Icon, { name: "home" }), i18n("home.title")] }) }), _jsx("li", { children: _jsxs("button", { type: "button", class: "side-menu-item has-children", onClick: handleDepartmentSelection, children: [_jsxs("span", { class: "d-flex align-items-center", children: [_jsx(Icon, { name: "grid" }), i18n("mobile.menu.shop.by.department")] }), _jsx(Icon, { name: "chevron-right" })] }) })] }) }), _jsx("section", { children: _jsxs("ul", { children: [_jsx("li", { children: _jsxs("a", { href: accountUrl, class: "side-menu-item", children: [_jsx(Icon, { name: "account" }), i18n("header.account")] }) }), _jsx("li", { children: _jsxs("a", { href: cartUrl, class: "side-menu-item", children: [_jsx(Icon, { name: "cart" }), i18n("header.cart.yourbasket")] }) })] }) }), _jsx("section", { children: _jsxs("ul", { children: [_jsx("li", { children: _jsxs("a", { href: helpCentreUrl, class: "side-menu-item", children: [_jsx(Icon, { name: "help" }), i18n("footer.contact-and-help")] }) }), _jsx("li", { children: _jsxs("a", { href: whereIsMyOrderUrl, class: "side-menu-item", children: [_jsx(Icon, { name: "package" }), i18n("footer.wheres-my-order")] }) }), _jsx("li", { children: _jsxs("a", { href: helpCentreUrl + "#faq-header", class: "side-menu-item", children: [_jsx(Icon, { name: "quiz" }), i18n("footer.faqs")] }) })] }) })] }));
};
