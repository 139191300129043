var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "jsx-dom/jsx-runtime";
export var Icon = function (_a) {
    var _b, _c;
    var name = _a.name, _d = _a.fill, fill = _d === void 0 ? "" : _d, _e = _a.className, className = _e === void 0 ? "" : _e, _f = _a.width, width = _f === void 0 ? 20 : _f, _g = _a.height, height = _g === void 0 ? 20 : _g, props = __rest(_a, ["name", "fill", "className", "width", "height"]);
    var spritesheet = (_c = (_b = window === null || window === void 0 ? void 0 : window.resourceUrls) === null || _b === void 0 ? void 0 : _b.iconSpriteSheet) !== null && _c !== void 0 ? _c : "/marketplace/images/sprite-icon.svg";
    return (_jsx("svg", __assign({ class: "icon ".concat(className), width: width, height: height }, props, { children: _jsx("use", { href: "".concat(spritesheet, "#").concat(name), fill: fill }) })));
};
