var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FruugoAnalyticsInteraction } from "./FruugoAnalyticsInteraction";
var pageType = window.pageType;
var FruugoAnalyticsClickEvent = /** @class */ (function (_super) {
    __extends(FruugoAnalyticsClickEvent, _super);
    function FruugoAnalyticsClickEvent(item) {
        var _a, _b, _c;
        var _this = _super.call(this, FruugoAnalyticsClickEvent.apiUrl) || this;
        _this.productId = item.id;
        _this.parentProductId = _this.parentProductId =
            pageType && pageType == "product" ? (_a = window === null || window === void 0 ? void 0 : window.skuInfo) === null || _a === void 0 ? void 0 : _a.productId : "";
        _this.premiumRetailer = (_c = (_b = window.retailerInformation) === null || _b === void 0 ? void 0 : _b.premiumSeller) !== null && _c !== void 0 ? _c : false;
        if (item.list_position) {
            _this.resultPosition = item.list_position;
        }
        if (item.isCarousel) {
            _this.eventName = _this.getCarouselName(item.list_name);
            _this.carouselPosition = _this.getCarouselPosition(item.list_name);
        }
        return _this;
    }
    FruugoAnalyticsClickEvent.apiUrl = "/event/log/click";
    return FruugoAnalyticsClickEvent;
}(FruugoAnalyticsInteraction));
export { FruugoAnalyticsClickEvent };
