var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "jsx-dom/jsx-runtime";
/** @jsxImportSource jsx-dom */
import { Logger, select, selectAll } from "@fruugo/utilities";
import { ModalType } from "./types";
import { RetailerInfoModalContent } from "./RetailerInfoModalContent";
import dialogPolyfill from "dialog-polyfill";
import { Icon } from "../Icon";
import { i18n } from "../../i18n";
var Log = new Logger(window.environment);
export var config = {
    openModalClass: ".js-open-modal",
    modalClass: ".js-modal",
    modalBodyClass: ".js-modal-body",
};
var defaultSettings = {
    allowClose: true,
};
export var Modal = function (_a) {
    var id = _a.id, children = _a.children, settings = _a.settings, handleClose = _a.handleClose;
    // Merge the settings with defaults
    var finalSettings = __assign(__assign({}, defaultSettings), settings);
    var allowClose = finalSettings.allowClose, ariaLabel = finalSettings.ariaLabel, ariaDescribedby = finalSettings.ariaDescribedby;
    return (_jsxs("dialog", { class: "js-modal dialog", "data-modal-type": id, "aria-modal": true, "aria-label": ariaLabel, "aria-describedby": ariaDescribedby, "aria-live": "polite", children: [allowClose && (_jsx("header", { children: _jsxs("button", { class: "unstyled d-flex align-items-center", onClick: handleClose, children: [_jsx(Icon, { name: "close", className: "mr-8" }), _jsx("span", { children: i18n("search.page.close") })] }) })), _jsx("article", { class: config.modalBodyClass.replace(".", ""), children: children })] }));
};
export function initModalTriggers() {
    var selector = selectAll(config.openModalClass);
    selector &&
        selector.forEach(function (element) {
            return element.addEventListener("click", function () { return handleModalTriggerClick(element); });
        });
}
function handleModalTriggerClick(element) {
    var type = getModalType(element);
    if (type) {
        var template = getModalTemplate(type);
        var settings = getModalSettings(type);
        template && openModal(type, template, settings);
    }
}
export function getModalById(id) {
    return select("".concat(config.modalClass, "[data-modal-type=\"").concat(id, "\"]"));
}
export function openModal(id, body, settings) {
    var modal = getModalById(id);
    var finalSettings = __assign(__assign({}, defaultSettings), settings);
    var allowClose = finalSettings.allowClose;
    var handleClose = function () {
        (settings === null || settings === void 0 ? void 0 : settings.onClose) && settings.onClose();
        closeModal();
    };
    if (!modal) {
        document.body.appendChild(_jsx(Modal, { id: id, settings: settings, handleClose: handleClose, children: body }));
        modal = modal = getModalById(id);
    }
    else {
        updateModal(id, body, settings);
    }
    // Polyfill for older browsers like Safari 15.2, can probably remove in 2026 after checking browser support.
    if (modal && !modal.showModal) {
        dialogPolyfill.registerDialog(modal);
    }
    modal === null || modal === void 0 ? void 0 : modal.showModal();
    if (allowClose) {
        modal === null || modal === void 0 ? void 0 : modal.addEventListener("mousedown", function (event) {
            return handleBackDropClick(event, handleClose);
        });
        document.removeEventListener("keydown", disableEscToClose);
    }
    else {
        modal === null || modal === void 0 ? void 0 : modal.removeEventListener("mousedown", function (event) {
            return handleBackDropClick(event, handleClose);
        });
        document.addEventListener("keydown", disableEscToClose);
    }
    // Provide a nice method to update the modal without specifying the ID
    var updateCurrentModal = function (body, settings) { return updateModal(id, body, settings); };
    return [closeModal, updateCurrentModal];
}
function disableEscToClose(event) {
    if (event.key === "Escape")
        event.preventDefault();
}
export function updateModal(id, body, settings) {
    body && updateModalBody(id, body);
    settings && updateModalSettings(id, settings);
}
export function updateModalSettings(id, settings) {
    var ariaLabel = settings.ariaLabel, ariaDescribedby = settings.ariaDescribedby;
    var modal = getModalById(id);
    if (modal) {
        ariaLabel && modal.setAttribute("aria-label", ariaLabel);
        ariaDescribedby && modal.setAttribute("aria-describedby", ariaDescribedby);
    }
}
export function updateModalBody(id, children) {
    var modal = getModalById(id);
    var body = modal === null || modal === void 0 ? void 0 : modal.querySelector(config.modalBodyClass);
    body && body.replaceChildren(_jsx(_Fragment, { children: children }));
}
/**
 * This creates a bug where clicking on the scrollbar closes the modal, but it's the lesser evil for now.
 * chrome 134 is adding a closedby attribute which will handle this out of the box, please replace with that when it's stable
 * https://developer.chrome.com/blog/chrome-134-beta#dialog_light_dismiss
 */
function handleBackDropClick(event, handleClose) {
    if (event.target === event.currentTarget)
        handleClose();
}
export function closeModal() {
    var modal = select("".concat(config.modalClass, "[open]"));
    if (!modal)
        return;
    if (modal) {
        document.removeEventListener("keydown", disableEscToClose);
        modal.close();
    }
}
function getModalType(element) {
    var type = element.dataset.modalType;
    if (!type) {
        Log.warning("[data-modal-type] attribute missing from selector when launching modal.");
        return null;
    }
    var modalType = mapModalType(type);
    if (!modalType) {
        Log.warning("Unsupported modal type: ".concat(type, ". Please add this type and setup a template for it."));
        return null;
    }
    return modalType;
}
function mapModalType(value) {
    if (Object.values(ModalType).includes(value)) {
        return value;
    }
    return undefined;
}
function getModalTemplate(type) {
    var template;
    switch (type) {
        case ModalType.RetailerInfo:
            template = _jsx(RetailerInfoModalContent, {});
            break;
        default:
            template = null;
            break;
    }
    if (!template) {
        Log.warning("Unable to get modal template for type: ".concat(type, "."));
        return null;
    }
    return template;
}
function getModalSettings(type) {
    var settings;
    switch (type) {
        case ModalType.RetailerInfo:
            settings = {
                ariaLabel: i18n("modal.store-information-label"),
                ariaDescribedby: "store-information-aria-description",
            };
            break;
    }
    return settings;
}
