import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "jsx-dom/jsx-runtime";
import { i18n } from "../i18n";
import dialogPolyfill from "dialog-polyfill";
import { select } from "@fruugo/utilities";
import { Icon } from "./Icon";
var config = {
    sideMenuClass: ".side-menu",
    closeSideMenuClass: ".js-close-side-menu",
    sideMenuBodyClass: ".js-side-menu-body",
};
export function openSideMenu(body) {
    var sideMenu = select(config.sideMenuClass);
    if (!sideMenu) {
        document.body.appendChild(_jsx(SideMenu, { children: body }));
        sideMenu = select(config.sideMenuClass);
        sideMenu && void sideMenu.offsetWidth; // Force reflow so the transition happens the first time its added to the page
    }
    else {
        updateSideMenuBody(body);
    }
    // Polyfill for older browsers like Safari 15.2, can probably remove in 2026 after checking browser support.
    if (sideMenu && !sideMenu.showModal) {
        dialogPolyfill.registerDialog(sideMenu);
    }
    sideMenu === null || sideMenu === void 0 ? void 0 : sideMenu.showModal();
    sideMenu === null || sideMenu === void 0 ? void 0 : sideMenu.addEventListener("mousedown", handleBackDropClick);
    focusFirstSideMenuItem();
}
export function closeSideMenu() {
    var sideMenu = select(config.sideMenuClass);
    sideMenu === null || sideMenu === void 0 ? void 0 : sideMenu.close();
}
/**
 * This creates a bug where clicking on the scrollbar closes the modal, but it's the lesser evil for now.
 * chrome 134 is adding a closedby attribute which will handle this out of the box, please replace with that when it's stable
 * https://developer.chrome.com/blog/chrome-134-beta#dialog_light_dismiss
 */
function handleBackDropClick(event) {
    if (event.target === event.currentTarget)
        closeSideMenu();
}
export function updateSideMenuBody(children) {
    var body = select(config.sideMenuBodyClass);
    body && body.replaceChildren(_jsx(_Fragment, { children: children }));
    focusFirstSideMenuItem();
}
export function focusFirstSideMenuItem() {
    var sideMenu = select(config.sideMenuClass);
    if (!sideMenu)
        return;
    var items = Array.from(sideMenu.querySelectorAll("ul li"));
    var _loop_1 = function (item) {
        var firstItem = item.querySelector("a, button");
        if (firstItem) {
            setTimeout(function () { return firstItem.focus(); }, 50);
            return "break";
        }
    };
    for (var _i = 0, items_1 = items; _i < items_1.length; _i++) {
        var item = items_1[_i];
        var state_1 = _loop_1(item);
        if (state_1 === "break")
            break;
    }
}
var SideMenu = function (_a) {
    var children = _a.children;
    return (_jsx("dialog", { class: config.sideMenuClass.replace(".", ""), children: _jsxs("div", { class: "h-100", children: [_jsxs("header", { class: "d-flex justify-content-between align-items-center sticky-section", children: [_jsx("img", { src: "/marketplace/images/logo-blue.svg", class: "logo", width: "80", height: "39", alt: "Fruugo" }), _jsx("button", { type: "button", title: i18n("search.page.close"), onClick: closeSideMenu, class: "button-icon header-icon clear ".concat(config.closeSideMenuClass.replace(".", "")), children: _jsx(Icon, { name: "close", width: 24, height: 24 }) })] }), _jsx("article", { class: config.sideMenuBodyClass.replace(".", ""), children: children })] }) }));
};
