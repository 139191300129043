import Glide, { Controls, Swipe, Images, Breakpoints, } from "@glidejs/glide/dist/glide.modular.esm";
import { showCarouselArrows } from "./product-collection";
import { select } from "@fruugo/utilities";
export var initCategoryCarousel = function () {
    var container = select(".js-category-carousel");
    if (!container)
        return;
    new Glide(".js-category-carousel", {
        type: "carousel",
        perView: 6,
        animationDuration: 800,
        direction: window.rtl ? "rtl" : "ltr",
        perSwipe: "|",
        gap: 20,
        breakpoints: {
            576: {
                perView: 2,
                peek: {
                    before: 16,
                    after: 50,
                },
                animationDuration: 200,
            },
            768: {
                perView: 3,
                peek: {
                    before: 16,
                    after: 50,
                },
                animationDuration: 300,
            },
            1280: {
                perView: 5,
                animationDuration: 800,
            },
            1600: {
                perView: 6,
            },
        },
    }).mount({ Breakpoints: Breakpoints, Controls: Controls, Swipe: Swipe, Images: Images });
    showCarouselArrows(container);
};
