var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FruugoAnalyticsBaseEvent } from "./FruugoAnalyticsBaseEvent";
// This search event should fire when a search is completed
// i.e After a user submits the search form and lands on the search page
var FruugoAnalyticsSearchCompleteEvent = /** @class */ (function (_super) {
    __extends(FruugoAnalyticsSearchCompleteEvent, _super);
    function FruugoAnalyticsSearchCompleteEvent(searchInfo) {
        var _this = _super.call(this, FruugoAnalyticsSearchCompleteEvent.apiUrl) || this;
        _this.searchQuery = encodeURIComponent(searchInfo.searchQuery);
        _this.queryParameters = searchInfo.queryParameters;
        _this.queryResultCount = searchInfo.queryResultCount;
        _this.queryCategoryFilters = searchInfo.queryCategoryFilters;
        _this.queryFacetFields = searchInfo.queryFacetFields;
        _this.queryTimestamp = searchInfo.queryTimestamp;
        _this.querySortParameters = searchInfo.querySortParameters;
        _this.queryResponseTime = searchInfo.queryResponseTime;
        _this.interactionId = searchInfo.interactionId;
        return _this;
    }
    FruugoAnalyticsSearchCompleteEvent.apiUrl = "/event/log/search-complete";
    return FruugoAnalyticsSearchCompleteEvent;
}(FruugoAnalyticsBaseEvent));
export { FruugoAnalyticsSearchCompleteEvent };
