var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { detect } from "detect-browser";
import { isMobileDevice } from "../../utilities";
import { defaultConsents, getUserConsent } from "@fruugo/utilities";
import { isInABTest } from "../../abTesting";
import { OnSiteAttribution } from "../../../types";
var browser = detect();
var FruugoAnalyticsBaseEvent = /** @class */ (function () {
    function FruugoAnalyticsBaseEvent(apiUrl) {
        var _this = this;
        this.eventName = window.pageType;
        this.platform = isMobileDevice() ? "mobile_web" : "desktop_web";
        this.browserName = browser && browser.name;
        this.browserVersion = browser && browser.version;
        this.interactionId = window.sessionStorage.getItem("interactionId");
        this.pageUrl = window.location.href;
        this.domain = window.location.hostname;
        this.buildData = function () {
            return __assign(__assign({}, _this), { 
                // We need to call this as a function when the event sends rather than when the class in initialised.
                // This is because the AB test cookie can change dynamically when users ajust their cookie settings
                onSiteAttribution: _this.getOnSiteAttribution() });
        };
        this.apiUrl = apiUrl;
    }
    FruugoAnalyticsBaseEvent.prototype.send = function () {
        var consents = getUserConsent() || defaultConsents;
        if (consents.analytics) {
            var data = this.buildData();
            navigator.sendBeacon(this.apiUrl, JSON.stringify(data, function (key, value) {
                // Exclude apiUrl property
                if (key === "apiUrl") {
                    return undefined;
                }
                return value;
            }));
        }
    };
    FruugoAnalyticsBaseEvent.prototype.getOnSiteAttribution = function () {
        return isInABTest("SHOP-858-BPCarousels", "B")
            ? OnSiteAttribution.BYTEPLUS
            : OnSiteAttribution.FRUUGO;
    };
    return FruugoAnalyticsBaseEvent;
}());
export { FruugoAnalyticsBaseEvent };
