import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "jsx-dom/jsx-runtime";
/** @jsxImportSource jsx-dom */
import { i18n } from "../../i18n";
import { FlagIcon } from "../FlagIcon";
import { Icon } from "../Icon";
export var retailerInfoModalAriaData = {
    ariaLabel: "Store information",
    ariaDescribedby: "Store information including seller details and shipping information",
};
var defaultBanner = "/marketplace/images/storefronts/default-storefront-1024.webp";
var getBadge = function (badgeImageUrl) {
    if (badgeImageUrl) {
        return (_jsx("div", { class: "Badge mt-n48 mx-auto", children: _jsx("img", { src: badgeImageUrl, alt: "Store logo" }) }));
    }
    return (_jsx("div", { class: "Badge Badge__Default Badge__Small mt-n48 mx-auto", children: _jsx(Icon, { name: "storefront", fill: "#fff", width: 24, height: 24 }) }));
};
export var RetailerInfoModalContent = function () {
    var _a = window.retailerInformation, name = _a.name, addressLine = _a.addressLine, companyNumber = _a.companyNumber, vrn = _a.vrn, premiumSeller = _a.premiumSeller, daysToDispatch = _a.daysToDispatch, shippingFromCountryName = _a.shippingFromCountryName, shippingFromCountryCode = _a.shippingFromCountryCode, shopUrl = _a.shopUrl, bannerImageUrl = _a.bannerImageUrl, badgeImageUrl = _a.badgeImageUrl;
    var banner = bannerImageUrl !== null && bannerImageUrl !== void 0 ? bannerImageUrl : defaultBanner;
    return (_jsxs(_Fragment, { children: [_jsx("section", { id: "store-information-aria-description", class: "sr-only", children: i18n("modal.store-information-description", name) }), _jsxs("div", { class: "position-relative", children: [_jsx("img", { src: banner, alt: "Store banner", class: "dialog-banner" }), _jsx("div", { class: "Hero__Fade" })] }), getBadge(badgeImageUrl), _jsxs("div", { class: "p-16", children: [_jsx("h2", { class: "text-center font-weight-light", children: name }), premiumSeller && (_jsxs("div", { class: "d-flex align-items-center mb-16", children: [_jsx(Icon, { name: "verified", fill: "#00a9ff", className: "mr-8" }), _jsx("strong", { children: i18n("retailer.premium-seller") })] })), addressLine && (_jsxs("div", { class: "d-flex align-items-center mb-16", children: [_jsx(Icon, { name: "location", className: "mr-8" }), _jsxs("p", { class: "m-0", children: [_jsxs("strong", { children: [i18n("product.retailer.address"), ": "] }), addressLine] })] })), companyNumber && (_jsxs("div", { class: "d-flex align-items-center mb-16", children: [_jsx(Icon, { name: "tag", className: "mr-8" }), _jsxs("p", { class: "m-0", children: [_jsxs("strong", { children: [i18n("product.retailer.company-number"), ": "] }), companyNumber] })] })), vrn && (_jsxs("div", { class: "d-flex align-items-center mb-16", children: [_jsx(Icon, { name: "find-in-page", className: "mr-8" }), _jsxs("p", { class: "m-0", children: [_jsxs("strong", { children: [i18n("product.retailer.vrn"), ": "] }), vrn] })] })), daysToDispatch != null && (_jsxs("div", { class: "d-flex align-items-center mb-16", children: [_jsx(Icon, { name: "acute", className: "mr-8" }), _jsx("p", { class: "m-0", children: _jsx("strong", { children: daysToDispatch > -1 && daysToDispatch <= 1
                                        ? i18n("product.dispatched.within.twentyfourhours")
                                        : i18n("product.dispatched.within", daysToDispatch.toString()) }) })] })), shippingFromCountryName && shippingFromCountryCode != "CN" && false && (_jsxs("div", { class: "d-flex align-items-center", children: [_jsx(Icon, { name: "delivery-truck-speed", className: "mr-8" }), _jsxs("p", { class: "m-0 d-flex", children: [_jsxs("strong", { class: "mr-8", children: [i18n("product.shipping-returns.shipping-origin-label"), " "] }), shippingFromCountryCode && (_jsx(FlagIcon, { country: shippingFromCountryCode, className: "mr-8", "aria-hidden": "true" })), shippingFromCountryName] })] }))] }), shopUrl && (_jsx("footer", { children: _jsx("a", { href: shopUrl, class: "button", children: i18n("product.retailer.visit", "") }) }))] }));
};
