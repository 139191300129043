import { Environment } from "@fruugo/utilities";
export function initKlarnaOnsiteMessaging() {
    var environment = window.environment, klarnaSettings = window.klarnaSettings;
    var paymentMethodAvailable = klarnaSettings.paymentMethodAvailable, onSiteMessagingClientId = klarnaSettings.onSiteMessagingClientId;
    if (!paymentMethodAvailable)
        return;
    var script = document.createElement("script");
    script.src = "https://js.klarna.com/web-sdk/v1/klarna.js";
    script.setAttribute("data-client-id", onSiteMessagingClientId);
    script.setAttribute("data-environment", environment === Environment.Production ? "production" : "playground");
    script.async = true;
    var head = document.getElementsByTagName("head")[0];
    head.appendChild(script);
    var element = document.querySelector("klarna-placement");
    element && element.classList.remove("d-none");
}
