import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "jsx-dom/jsx-runtime";
/** @jsxImportSource jsx-dom */
import { createEcommerceProductPayload, sendGoogleAnalyticsEvent, } from "../../analytics/google-analytics";
import { i18n } from "../../i18n";
import { closeModal } from "./Modal";
import { ProductCartRow } from "./ProductCartRow";
export var ProductAddedToCartModalContent = function (_a) {
    var cartValid = _a.cartValid, success = _a.success, checkoutUrl = _a.checkoutUrl, message = _a.message, quantity = _a.quantity;
    return (_jsxs(_Fragment, { children: [_jsxs("div", { class: "p-16", children: [!success && (_jsxs(_Fragment, { children: [_jsx("div", { class: "alert alert-danger", children: message }), _jsx("section", { id: "added-to-cart-aria-description", class: "sr-only", children: message })] })), success && (_jsxs(_Fragment, { children: [_jsx("h2", { class: "text-center font-weight-light", children: i18n("product.modal.addedtocart") }), _jsx("section", { id: "added-to-cart-aria-description", class: "sr-only", children: i18n("modal.added-to-cart.success") }), quantity && _jsx(ProductCartRow, { quantity: quantity })] }))] }), _jsxs("footer", { class: "d-flex justify-content-between flex-column-reverse flex-sm-row", children: [_jsx("button", { class: "button", onClick: handleContinue, children: i18n("product.modal.continueshopping") }), _jsxs("div", { class: "d-flex flex-column-reverse flex-sm-row", children: [_jsx("a", { href: window.pageUrls.cartUrl, onClick: handleViewBasket, class: "button button-black mb-16 mb-sm-0 mr-sm-16", children: i18n("product.modal.viewbasket") }), cartValid && checkoutUrl && (_jsx("a", { href: checkoutUrl, onClick: handleCheckout, class: "button button-orange mb-16 mb-sm-0", children: i18n("cart.summary.checkout") }))] })] })] }));
};
function handleContinue() {
    sendGoogleAnalyticsEvent("custom_event", "pdp-atc-modal-interaction", "click-continue");
    closeModal();
}
function handleViewBasket() {
    sendGoogleAnalyticsEvent("custom_event", "pdp-atc-modal-interaction", "click-basket");
}
function handleCheckout() {
    sendGoogleAnalyticsEvent("custom_event", "pdp-atc-modal-interaction", "click-checkout");
    sendGoogleAnalyticsEvent("legacy_event", "checkout interaction", "commence checkout");
    gtag("event", "begin_checkout", {
        items: createEcommerceProductPayload([{ quantity: 1, sku: window.skuInfo }], "product"),
    });
}
