import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "jsx-dom/jsx-runtime";
/** @jsxImportSource jsx-dom */
import { i18n } from "../../i18n";
import { GeoIPProductNotAvailable } from "./GeoIPProductNotAvailable";
import { closeModal } from "./Modal";
export var GeoIPModalContent = function (_a) {
    var currentCountryDomain = _a.currentCountryDomain, currentCountry = _a.currentCountry, geoIPDomain = _a.geoIPDomain, geoIPCountry = _a.geoIPCountry, geoIPLanguage = _a.geoIPLanguage, primaryLink = _a.primaryLink, _b = _a.productAvailable, productAvailable = _b === void 0 ? true : _b, productCategoryUrl = _a.productCategoryUrl, productCategoryName = _a.productCategoryName;
    var geoIpCountryString = i18n("common.country." + geoIPCountry, undefined, geoIPLanguage);
    var currentCountryString = i18n("common.country." + currentCountry, undefined, geoIPLanguage);
    return (_jsxs(_Fragment, { children: [_jsx("section", { id: "geo-ip-aria-description", class: "sr-only", children: i18n("modal.geoip.description", geoIpCountryString) }), _jsx("article", { class: "p-16", children: _jsxs("div", { class: "js-consent-screen-home", children: [_jsx("h2", { class: "text-center font-weight-light", children: i18n("geoipmodal.greeting", geoIpCountryString, geoIPLanguage) }), _jsx("p", { children: i18n("geoipmodal.currentlyShopping", [currentCountryString, currentCountryDomain], geoIPLanguage) }), _jsx("p", { class: "m-0", children: i18n("geoipmodal.localShopping", [geoIpCountryString, geoIPDomain], geoIPLanguage) })] }) }), !productAvailable && productCategoryUrl && productCategoryName && (_jsx(GeoIPProductNotAvailable, { geoIpCountryString: geoIpCountryString, geoIPLanguage: geoIPLanguage, productCategoryUrl: productCategoryUrl, productCategoryName: productCategoryName })), _jsxs("footer", { class: "d-flex justify-content-between flex-column flex-sm-row", children: [_jsx("button", { class: "button button-black mb-16 mb-sm-0", onClick: handleButtonClick, children: i18n("geoipmodal.noStay", currentCountryString, geoIPLanguage) }), _jsx("a", { href: primaryLink, class: "button", target: "_blank", onClick: handleButtonClick, children: i18n("geoipmodal.yesTakeMe", geoIPDomain, geoIPLanguage) })] })] }));
};
export var handleButtonClick = function () {
    localStorage.setItem("geoIPModalDismissed", "true");
    closeModal();
};
