import { jsx as _jsx, jsxs as _jsxs } from "jsx-dom/jsx-runtime";
/** @jsxImportSource jsx-dom */
import { i18n } from "../i18n";
import { Icon } from "./Icon";
export var Banner = function () {
    var handleClick = function () {
        var banner = document.getElementById("swissTaxBanner");
        if (!banner)
            return;
        banner.classList.add("d-none");
        window.sessionStorage.setItem("closed_swiss_tax_banner", "true");
    };
    return (_jsxs("div", { class: "Banner Banner--Green", role: "banner", id: "swissTaxBanner", children: [_jsx("p", { class: "Banner__Text Banner__Text--Left", dangerouslySetInnerHTML: {
                    __html: i18n("banner.swiss-tax-changes", "/help/detail/swiss-tax-changes?language=".concat(window.currentLang)),
                } }), _jsx("button", { class: "Banner__Button", onClick: function () { return handleClick(); }, children: _jsx(Icon, { name: "close" }) })] }));
};
