import { jsx as _jsx, jsxs as _jsxs } from "jsx-dom/jsx-runtime";
/** @jsxImportSource jsx-dom */
import { SearchRecommendationType } from "../../types";
import { sendGoogleAnalyticsEvent } from "../analytics/google-analytics";
import { Icon } from "./Icon";
export var SearchRecommendations = function (_a) {
    var query = _a.query, recommendations = _a.recommendations;
    function boldifySearchQuery(name) {
        var regex = new RegExp("(".concat(query, ")"), "gi");
        return name.replace(regex, "<strong>$1</strong>");
    }
    function getUrl(link) {
        try {
            var _a = window.location, protocol = _a.protocol, hostname = _a.hostname, port = _a.port;
            var baseUrl = "".concat(protocol, "//").concat(hostname).concat(port ? ":".concat(port) : "");
            var url = new URL(baseUrl + link);
            return url.toString();
        }
        catch (err) {
            return link;
        }
    }
    function sendGAEvent(recommendation) {
        var type = recommendation.type, name = recommendation.name;
        var eventName = "unknown";
        switch (type) {
            case SearchRecommendationType.Webshop:
                eventName = "webshop";
                break;
            case SearchRecommendationType.Warehouse:
                eventName = "warehouse";
                break;
            case SearchRecommendationType.QuerySuggestion:
                eventName = "search-term";
                break;
        }
        eventName = "".concat(eventName, "-recommendation-click");
        sendGoogleAnalyticsEvent("custom_event", eventName, name);
    }
    return recommendations.map(function (recommendation) { return (_jsx("li", { children: _jsxs("a", { href: getUrl(recommendation.link), onClick: function () { return sendGAEvent(recommendation); }, children: [recommendation.type === SearchRecommendationType.Webshop && (_jsx(Icon, { name: "category", "aria-hidden": "true" })), recommendation.type === SearchRecommendationType.Warehouse && (_jsx(Icon, { name: "sell", "aria-hidden": "true" })), recommendation.type === SearchRecommendationType.QuerySuggestion && (_jsx(Icon, { name: "search", "aria-hidden": "true" })), _jsx("span", { dangerouslySetInnerHTML: {
                        __html: boldifySearchQuery(recommendation.name),
                    } })] }) })); });
};
