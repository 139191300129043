var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx } from "jsx-dom/jsx-runtime";
import { getGeoIPProductData } from "./api";
import { GeoIPModalContent, handleButtonClick as handleClose, } from "./components/modal/GeoIPModalContent";
import { getModalById, openModal } from "./components/modal/Modal";
import { ModalType } from "./components/modal/types";
import { i18n, initTranslation } from "./i18n.js";
export default function initGeoIPModal() {
    return __awaiter(this, void 0, void 0, function () {
        var currentCountryDomain, geoIPDomain, currentCountry, geoIPCountry, geoIPLanguage, messagePropertiesResourceUrlForGeoIP, triggerGeoIPModal;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    currentCountryDomain = window.currentCountryDomain, geoIPDomain = window.geoIPDomain, currentCountry = window.currentCountry, geoIPCountry = window.geoIPCountry, geoIPLanguage = window.geoIPLanguage, messagePropertiesResourceUrlForGeoIP = window.messagePropertiesResourceUrlForGeoIP;
                    // Exit immediately if GeoIP modal has been previously dismissed or current country same as local country
                    if (localStorage.getItem("geoIPModalDismissed") ||
                        !geoIPDomain ||
                        geoIPDomain === "" ||
                        currentCountryDomain === geoIPDomain) {
                        return [2 /*return*/];
                    }
                    return [4 /*yield*/, initTranslation(messagePropertiesResourceUrlForGeoIP)];
                case 1:
                    _a.sent();
                    triggerGeoIPModal = function () {
                        var productId = window.productId, skuId = window.skuId;
                        // Disable if the cookie consent modal is open
                        if (cookieConsentModalIsOpen())
                            return;
                        var modalSettings = {
                            ariaLabel: i18n("modal.geoip.label"),
                            ariaDescribedby: "geo-ip-aria-description",
                            onClose: handleClose,
                        };
                        // If is on product page
                        if (productId && skuId) {
                            getGeoIPProductData(productId, skuId)
                                .then(function (data) {
                                var productExistsInGeoIpCountry = data.productExistsInGeoIpCountry, productCategoryName = data.productCategoryName, productCategoryURL = data.productCategoryURL, productLocationURL = data.productLocationURL;
                                if (productExistsInGeoIpCountry === false && productCategoryURL) {
                                    openModal(ModalType.GeoIP, _jsx(GeoIPModalContent, { currentCountryDomain: currentCountryDomain, currentCountry: currentCountry, geoIPDomain: geoIPDomain, geoIPCountry: geoIPCountry, geoIPLanguage: geoIPLanguage, primaryLink: "https://".concat(geoIPDomain), productAvailable: false, productCategoryName: productCategoryName, productCategoryUrl: productCategoryURL }), modalSettings);
                                }
                                else if (productExistsInGeoIpCountry === true &&
                                    productLocationURL) {
                                    openModal(ModalType.GeoIP, _jsx(GeoIPModalContent, { currentCountryDomain: currentCountryDomain, currentCountry: currentCountry, geoIPDomain: geoIPDomain, geoIPCountry: geoIPCountry, geoIPLanguage: geoIPLanguage, primaryLink: productLocationURL }), modalSettings);
                                }
                                else {
                                    openModal(ModalType.GeoIP, _jsx(GeoIPModalContent, { currentCountryDomain: currentCountryDomain, currentCountry: currentCountry, geoIPDomain: geoIPDomain, geoIPCountry: geoIPCountry, geoIPLanguage: geoIPLanguage, primaryLink: "https://".concat(geoIPDomain) }), modalSettings);
                                }
                            })
                                .catch(function () {
                                openModal(ModalType.GeoIP, _jsx(GeoIPModalContent, { currentCountryDomain: currentCountryDomain, currentCountry: currentCountry, geoIPDomain: geoIPDomain, geoIPCountry: geoIPCountry, geoIPLanguage: geoIPLanguage, primaryLink: "https://".concat(geoIPDomain) }), modalSettings);
                            });
                        }
                        else {
                            openModal(ModalType.GeoIP, _jsx(GeoIPModalContent, { currentCountryDomain: currentCountryDomain, currentCountry: currentCountry, geoIPDomain: geoIPDomain, geoIPCountry: geoIPCountry, geoIPLanguage: geoIPLanguage, primaryLink: "https://".concat(geoIPDomain) }), modalSettings);
                        }
                    };
                    setTimeout(triggerGeoIPModal, 1500);
                    return [2 /*return*/];
            }
        });
    });
}
function cookieConsentModalIsOpen() {
    var modal = getModalById(ModalType.CookieConsent);
    return modal ? modal.open : false;
}
