var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FruugoAnalyticsBaseEvent } from "./FruugoAnalyticsBaseEvent";
// This search event should fire when a search is initialised
// i.e when a user submits the search form
var FruugoAnalyticsSearchEvent = /** @class */ (function (_super) {
    __extends(FruugoAnalyticsSearchEvent, _super);
    function FruugoAnalyticsSearchEvent(searchQuery) {
        var _this = _super.call(this, FruugoAnalyticsSearchEvent.apiUrl) || this;
        _this.searchQuery = searchQuery;
        return _this;
    }
    FruugoAnalyticsSearchEvent.apiUrl = "/event/log/search";
    return FruugoAnalyticsSearchEvent;
}(FruugoAnalyticsBaseEvent));
export { FruugoAnalyticsSearchEvent };
