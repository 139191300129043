import { jsx as _jsx } from "jsx-dom/jsx-runtime";
/** @jsxImportSource jsx-dom */
/* globals gtag:false */
import { i18n, waitForTranslations } from "./i18n.js";
import { initTrustpilot, hideContainers as hideTrustPilotContainers, } from "./trustpilot.js";
import { initBing, disableBingTracking } from "./bing.js";
import { initHotjar } from "./hotjar.js";
import { initClearpay } from "./clearpay.js";
import { enableFacebookPixel } from "./analytics/facebook-pixel.js";
import { initTradeDoublerTrackingLibrary } from "./analytics/tradedoubler.js";
import { getUserConsent, defaultConsents, setUserConsent, selectAll, } from "@fruugo/utilities";
import { initPaymentIcons } from "./payment-icons.js";
import { openModal } from "./components/modal/Modal";
import { ModalType } from "./components/modal/types";
import { ConsentModalContent } from "./components/modal/ConsentModalContent";
import xhr from "./xhr";
export var consentModalBlacklist = ["/help/detail/cookies"];
function consentOverridePresent() {
    var params = new URL(document.location.href).searchParams;
    return params.get("consent") !== null;
}
export function initCookieConsent() {
    selectAll(".js-manage-cookies").forEach(function (link) {
        return link.addEventListener("click", function (e) {
            e.preventDefault();
            var consents = getUserConsent() || defaultConsents;
            showConsentModal(consents);
        });
    });
    if (consentModalBlacklist.includes(window.location.pathname)) {
        return;
    }
    // load persisted consent
    var consents = getUserConsent();
    if (consents) {
        enableFunctionality(consents);
    }
    else {
        if (consentOverridePresent()) {
            return;
        }
        showConsentModal(defaultConsents);
    }
    return;
}
function showConsentModal(consents) {
    waitForTranslations().then(function () {
        openModal(ModalType.CookieConsent, _jsx(ConsentModalContent, { consents: consents }), {
            allowClose: false,
            ariaLabel: i18n("modal.consent.label"),
        });
    });
}
export function updateConsents(consents) {
    setUserConsent(consents);
    // ping the api to get/set back end cookies, mirror the current search string in case it includes referrer information
    xhr("/marketplace/api/consent".concat(window.location.search), "POST");
    enableFunctionality(consents);
}
function reloadAbTests() {
    // ABTEST CXO2-218_Oney
    initPaymentIcons(".js-payment-icons", true);
}
// everything in here needs to be idempotent as this can be called multiple times by the user
function enableFunctionality(consents) {
    gtag("consent", "update", {
        ad_storage: consents.advertising === true ? "granted" : "denied",
        ad_user_data: consents.advertising === true ? "granted" : "denied",
        ad_personalization: consents.advertising === true ? "granted" : "denied",
        analytics_storage: consents.analytics === true ? "granted" : "denied",
    });
    if (consents.analytics) {
        if (window.hotjarSiteId) {
            initHotjar();
        }
        reloadAbTests();
    }
    if (consents.advertising) {
        initTrustpilot();
        initBing();
        initClearpay();
        enableFacebookPixel();
        initTradeDoublerTrackingLibrary();
    }
    else {
        hideTrustPilotContainers();
        disableBingTracking();
    }
    var consentUpdateEvent = new CustomEvent("consentUpdate", {
        detail: {
            consents: consents,
        },
        bubbles: true,
        cancelable: true,
    });
    document.dispatchEvent(consentUpdateEvent);
}
