var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { jsx as _jsx, Fragment as _Fragment } from "jsx-dom/jsx-runtime";
/** @jsxImportSource jsx-dom */
import { DeviceWidth } from "../types";
import { minWidth } from "./utilities";
import { openSideMenu } from "./components/SideMenu";
import { CategoryMenu } from "./components/CategoryMenu";
import { hideBackdrop, showBackdrop } from "./components/Backdrop";
import dialogPolyfill from "dialog-polyfill";
import { getSearchRecommendations } from "./api";
import { debounce, Logger, select } from "@fruugo/utilities";
import { SearchRecommendations } from "./components/SearchRecommendations";
import { fadeIn } from "./animations";
import { isInABTest } from "../modules/abTesting.js";
import { FruugoAnalyticsSearchEvent } from "./analytics/fruugo-analytics/FruugoAnalyticsSearchEvent";
var Log = new Logger(window.environment);
var SearchBarBase = /** @class */ (function () {
    function SearchBarBase(config) {
        var _this = this;
        this.debounceDelay = 280;
        this.isValidSearch = function (query) {
            return query && query.length >= _this.config.minLengthForSearch;
        };
        this.handleFormSubmit = function (event) {
            var form = event.target;
            var data = new FormData(form);
            var query = data.get("q");
            if (_this.isValidSearch(query)) {
                var event_1 = new FruugoAnalyticsSearchEvent(query);
                event_1.send();
            }
        };
        this.handleSearchRecommendations = debounce(function (event) {
            var target = event.target;
            var query = target.value;
            var element = select(_this.config.searchRecommendationsClass);
            var clearRecommendations = function () {
                element === null || element === void 0 ? void 0 : element.replaceChildren();
            };
            if (!query ||
                query.length < _this.config.minLengthForSearchRecommendations) {
                clearRecommendations();
                return;
            }
            var loading = function () {
                element === null || element === void 0 ? void 0 : element.replaceChildren(_jsx("li", { children: _jsx("div", { class: "skeleton-animate fadeOut mx-24 mb-8", style: "height: 24px" }) }));
                var skeleton = element === null || element === void 0 ? void 0 : element.querySelector(".skeleton-animate");
                skeleton && fadeIn(skeleton);
            };
            loading();
            getSearchRecommendations(query)
                .then(function (recommendations) {
                if (!recommendations || recommendations.length < 1) {
                    clearRecommendations();
                    return;
                }
                element &&
                    element.replaceChildren(_jsx(_Fragment, { children: _jsx(SearchRecommendations, { query: query, recommendations: recommendations }) }));
            })
                .catch(function (error) {
                clearRecommendations();
                if (error.name === "AbortError") {
                    Log.error("Search recommendation request timed out. Search Query: ".concat(query));
                }
                else {
                    Log.error(error);
                }
            });
        }, this.debounceDelay);
        this.config = config;
        this.searchBar = select(config.searchBarClass);
        this.searchBarInput = select(this.config.searchBarInputClass);
        this.debounceDelay = config.debounceDelay;
        // Polyfill for older browsers like Safari 15.2
        if (this.searchBar && !this.searchBar.showModal) {
            dialogPolyfill.registerDialog(this.searchBar);
        }
    }
    return SearchBarBase;
}());
var MobileSearchBar = /** @class */ (function (_super) {
    __extends(MobileSearchBar, _super);
    function MobileSearchBar(config) {
        var _this = _super.call(this, config) || this;
        _this.show = function () {
            var _a, _b;
            (_a = _this.searchBar) === null || _a === void 0 ? void 0 : _a.showModal();
            (_b = _this.searchBarInput) === null || _b === void 0 ? void 0 : _b.focus();
        };
        _this.close = function () {
            var _a, _b;
            if ((_a = _this.searchBar) === null || _a === void 0 ? void 0 : _a.open)
                (_b = _this.searchBar) === null || _b === void 0 ? void 0 : _b.close();
        };
        _this.handleBackdropClick = function (event) {
            if (event.target === event.currentTarget)
                _this.close();
        };
        _this.handleCategorySelection = function () {
            _this.close();
            openSideMenu(_jsx(CategoryMenu, {}));
        };
        return _this;
    }
    MobileSearchBar.prototype.init = function () {
        var _a, _b, _c, _d, _e, _f;
        (_a = select(this.config.openSearchBarClass)) === null || _a === void 0 ? void 0 : _a.addEventListener("click", this.show);
        (_b = select(this.config.openCategorySelectionClass)) === null || _b === void 0 ? void 0 : _b.addEventListener("click", this.handleCategorySelection);
        (_c = select(this.config.closeSearchBarClass)) === null || _c === void 0 ? void 0 : _c.addEventListener("click", this.close);
        (_d = select(this.config.searchBarFormClass)) === null || _d === void 0 ? void 0 : _d.addEventListener("submit", this.handleFormSubmit);
        (_e = this.searchBar) === null || _e === void 0 ? void 0 : _e.addEventListener("mousedown", this.handleBackdropClick);
        if (isInABTest("SearchRecommendation", "B")) {
            (_f = this.searchBarInput) === null || _f === void 0 ? void 0 : _f.addEventListener("input", this.handleSearchRecommendations);
        }
    };
    MobileSearchBar.prototype.cleanup = function () {
        var _a, _b, _c, _d, _e, _f;
        if ((_a = this.searchBar) === null || _a === void 0 ? void 0 : _a.open)
            this.close();
        (_b = select(this.config.openSearchBarClass)) === null || _b === void 0 ? void 0 : _b.removeEventListener("click", this.show);
        (_c = select(this.config.openCategorySelectionClass)) === null || _c === void 0 ? void 0 : _c.removeEventListener("click", this.handleCategorySelection);
        (_d = select(this.config.closeSearchBarClass)) === null || _d === void 0 ? void 0 : _d.removeEventListener("click", this.close);
        (_e = this.searchBar) === null || _e === void 0 ? void 0 : _e.removeEventListener("mousedown", this.handleBackdropClick);
        if (isInABTest("SearchRecommendation", "B")) {
            (_f = this.searchBarInput) === null || _f === void 0 ? void 0 : _f.removeEventListener("input", this.handleSearchRecommendations);
        }
    };
    return MobileSearchBar;
}(SearchBarBase));
var DesktopSearchBar = /** @class */ (function (_super) {
    __extends(DesktopSearchBar, _super);
    function DesktopSearchBar(config) {
        var _this = _super.call(this, config) || this;
        _this.show = function () {
            var _a, _b, _c;
            if ((_a = _this.searchBar) === null || _a === void 0 ? void 0 : _a.classList.contains("has-focus"))
                return;
            (_b = _this.searchBar) === null || _b === void 0 ? void 0 : _b.classList.add("has-focus");
            showBackdrop();
            (_c = _this.searchBarInput) === null || _c === void 0 ? void 0 : _c.focus();
        };
        _this.close = function () {
            var _a, _b;
            if (!((_a = _this.searchBar) === null || _a === void 0 ? void 0 : _a.classList.contains("has-focus")))
                return;
            (_b = _this.searchBar) === null || _b === void 0 ? void 0 : _b.classList.remove("has-focus");
            hideBackdrop();
        };
        _this.handleCategorySelection = function () {
            _this.close();
            openSideMenu(_jsx(CategoryMenu, {}));
        };
        _this.handleCategorySelectionKeyboard = function (event) {
            var keyboardEvent = event;
            if (keyboardEvent.key === "Enter" || keyboardEvent.key === " ") {
                event.preventDefault();
                _this.handleCategorySelection();
            }
        };
        _this.handleFocusIn = function (event) {
            var _a;
            if ((_a = _this.searchBar) === null || _a === void 0 ? void 0 : _a.contains(event.target)) {
                _this.show();
            }
        };
        _this.handleFocusOut = function (event) {
            // We add a delay here to stop a bug in Mac Safari where the focusout event closes the searchbar
            // before the click event fires, preventing the click from working
            setTimeout(function () {
                var _a;
                if (!((_a = _this.searchBar) === null || _a === void 0 ? void 0 : _a.contains(event.relatedTarget))) {
                    _this.close();
                }
            }, 250);
        };
        return _this;
    }
    DesktopSearchBar.prototype.init = function () {
        var _a, _b, _c, _d, _e, _f;
        (_a = this.searchBar) === null || _a === void 0 ? void 0 : _a.addEventListener("focusin", this.handleFocusIn);
        (_b = this.searchBar) === null || _b === void 0 ? void 0 : _b.addEventListener("focusout", this.handleFocusOut);
        (_c = select(this.config.openCategorySelectionClass)) === null || _c === void 0 ? void 0 : _c.addEventListener("click", this.handleCategorySelection);
        // Add the keydown event for keyboard support when selecting "Shop by departments"
        (_d = select(this.config.openCategorySelectionClass)) === null || _d === void 0 ? void 0 : _d.addEventListener("keydown", this.handleCategorySelectionKeyboard);
        (_e = select(this.config.searchBarFormClass)) === null || _e === void 0 ? void 0 : _e.addEventListener("submit", this.handleFormSubmit);
        if (isInABTest("SearchRecommendation", "B")) {
            (_f = this.searchBarInput) === null || _f === void 0 ? void 0 : _f.addEventListener("input", this.handleSearchRecommendations);
        }
    };
    DesktopSearchBar.prototype.cleanup = function () {
        var _a, _b, _c, _d, _e, _f;
        this.close();
        (_a = this.searchBar) === null || _a === void 0 ? void 0 : _a.removeEventListener("focusin", this.handleFocusIn);
        (_b = this.searchBar) === null || _b === void 0 ? void 0 : _b.removeEventListener("focusout", this.handleFocusOut);
        (_c = select(this.config.openCategorySelectionClass)) === null || _c === void 0 ? void 0 : _c.removeEventListener("click", this.handleCategorySelection);
        (_d = select(this.config.openCategorySelectionClass)) === null || _d === void 0 ? void 0 : _d.removeEventListener("keydown", this.handleCategorySelectionKeyboard);
        (_e = select(this.config.searchBarFormClass)) === null || _e === void 0 ? void 0 : _e.removeEventListener("submit", this.handleFormSubmit);
        if (isInABTest("SearchRecommendation", "B")) {
            (_f = this.searchBarInput) === null || _f === void 0 ? void 0 : _f.removeEventListener("input", this.handleSearchRecommendations);
        }
    };
    return DesktopSearchBar;
}(SearchBarBase));
export function initSearchBar() {
    var config = {
        searchBarClass: ".js-search-bar",
        openSearchBarClass: ".js-open-search-bar",
        closeSearchBarClass: ".js-close-search-bar",
        searchBarInputClass: ".js-search-bar-input",
        searchBarFormClass: ".js-search-bar-form",
        openCategorySelectionClass: ".js-open-category-selection",
        searchRecommendationsClass: ".js-search-recommendations",
        debounceDelay: 280,
        minLengthForSearch: 2,
        minLengthForSearchRecommendations: 3,
    };
    var mobileSearchBar = new MobileSearchBar(config);
    var desktopSearchBar = new DesktopSearchBar(config);
    minWidth(DeviceWidth.Medium, function () {
        mobileSearchBar.cleanup();
        desktopSearchBar.init();
    }, function () {
        desktopSearchBar.cleanup();
        mobileSearchBar.init();
    });
}
