import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "jsx-dom/jsx-runtime";
import { ManageConsentScreen } from "./ManageConsentScreen";
import { i18n } from "../../i18n";
import { closeModal, updateModal } from "./Modal";
import { ModalType } from "./types";
import { updateConsents } from "../../consent";
export var ConsentModalContent = function (_a) {
    var consents = _a.consents;
    return (_jsxs(_Fragment, { children: [_jsx("article", { class: "p-16", children: _jsxs("div", { class: "js-consent-screen-home", children: [_jsx("h2", { class: "text-center font-weight-light", children: i18n("consent.home.title") }), _jsx("p", { dangerouslySetInnerHTML: {
                                __html: i18n("consent.home.text"),
                            } })] }) }), _jsxs("footer", { class: "d-flex justify-content-between flex-column flex-sm-row", children: [_jsx("button", { class: "button mb-16 mb-sm-0", onClick: handleAcceptAll, children: i18n("consent.actions.accept") }), _jsxs("div", { class: "d-flex flex-column flex-sm-row", children: [_jsx("button", { class: "button button-black mb-16 mb-sm-0 mr-sm-16", onClick: function () { return handleManage(consents); }, children: i18n("consent.actions.manage") }), _jsx("button", { class: "button button-orange", onClick: handleDeclineAll, children: i18n("consent.actions.decline") })] })] })] }));
};
var handleAcceptAll = function () {
    var consents = {
        essential: true,
        analytics: true,
        advertising: true,
    };
    updateConsents(consents);
    closeModal();
};
var handleManage = function (consents) {
    updateModal(ModalType.CookieConsent, _jsx(ManageConsentScreen, { consents: consents, handleAcceptAll: handleAcceptAll, handleDeclineAll: handleDeclineAll }));
};
export var handleDeclineAll = function () {
    var consents = {
        essential: true,
        analytics: false,
        advertising: false,
    };
    updateConsents(consents);
    closeModal();
};
