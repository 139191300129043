import { jsx as _jsx, jsxs as _jsxs } from "jsx-dom/jsx-runtime";
/** @jsxImportSource jsx-dom */
import { select } from "@fruugo/utilities";
import { i18n } from "../../i18n";
export var ProductCartRow = function (_a) {
    var quantity = _a.quantity;
    var titleElement = select(".js-product-title");
    var title = titleElement && titleElement.textContent
        ? titleElement.textContent.trim()
        : i18n("product.details");
    var _b = window.skuInfo, images = _b.images, shipping = _b.shipping, price = _b.price;
    var hasVoucher = window.voucher;
    var total = price.totalInclTaxAfterDiscount
        ? price.totalInclTaxAfterDiscount
        : price.totalInclTax;
    return (_jsxs("div", { class: "row modal-product-details", children: [_jsx("div", { class: "col-2 d-none d-sm-block", children: _jsx("img", { src: images.urlSmall, alt: "Thumbnail image" }) }), _jsxs("div", { class: "col-12 col-sm-10 row", children: [_jsxs("em", { class: "col-2 col-sm-1 p-sm-0 mr-sm-2", children: [quantity, " x"] }), _jsxs("div", { class: "col row pr-0", children: [_jsxs("div", { class: "col-12 col-sm p-0", children: [_jsx("span", { class: "modal_product_title", children: title }), shipping.methods &&
                                        shipping.methods.some(function (method) { return method.numericPrice === 0; }) && (_jsx("span", { class: "savings_shipping_small d-block free_shipping", children: i18n("product.free.shipping") })), price.taxCalculationRequiredInCheckout === true && (_jsx("small", { class: "text-muted", children: i18n("product.tax.checkout") }))] }), _jsxs("div", { class: "col-12 col-sm-7 p-0 pl-sm-2 pr-sm-0 cost ".concat(hasVoucher ? "col-12 col-sm-7" : "col"), children: [price.totalInclTaxAfterDiscount && (_jsxs("p", { class: "cost__rrp", children: [i18n("product.recommendedPrice"), _jsxs("del", { children: [" ", price.totalInclTax] })] })), hasVoucher && (_jsx("p", { class: "cost__rrp", children: i18n("product.voucher.voucher-discount", price.voucherDiscount) })), hasVoucher && (_jsxs("p", { class: "cost__rrp", children: [i18n("product.saving"), _jsxs("span", { class: "cost__price", children: [" ", price.totalDiscount] }), _jsxs("span", { class: "cost__price", children: [" (", price.discountPercentage, ")"] })] })), !hasVoucher && price.numericDiscountPercentage && (_jsxs("p", { class: "savings_shipping_small d-block", children: ["-", price.discountPercentage] })), _jsxs("p", { children: [i18n("product.price"), " ", _jsx("span", { class: "cost__price", children: total })] })] })] })] })] }));
};
